import React from 'react';
import { NavLink } from 'react-router-dom';
import b24Styles from './b24.module.css';

// import b24Image from '../../images/window.png'
import b24BackImage from '../../images/stl.jpg'
import b24CloudImage from '../../images/cloud.png'
import b24BoxImage from '../../images/box.png'

import Page from '../../components/page/page';
import Minicard from '../../components/minicard/minicard';

import appStyles from '../../components/app/app.module.css'
import B24About from '../../components/b24-about/b24-about';

const B24 = ({ orderClick }) => {
  const b24form = (w, d, u) => {
    const s = d.createElement('script');
    s.async = true; s.src = u + '?' + (Date.now() / 180000 | 0);
    var h = d.getElementsByTagName('script')[0];
    h.parentNode.insertBefore(s, h);
  };

  return (
    <Page title={'Битрикс24'} backImage={b24BackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['caption']}`}>
          <p className={`${appStyles['caption__text']}`}>&nbsp;</p>
        </div>
      </div>
      <B24About orderClick={orderClick}/>
      <div className={appStyles.minicards}>
        <NavLink className={appStyles.cardLink} to="/b24/cloud">
          <Minicard
            title={<span>Битрикс24<br />Облачный</span>}
            image={b24CloudImage}
            text={'Гибкое управление всей структурой проекта и доступ к нему из любой точки мира, где есть интернет'}
          />
        </NavLink>
        <NavLink className={appStyles.cardLink} to="/b24/box">
          <Minicard
            title={<span>Битрикс24<br />Коробочный</span>}
            image={b24BoxImage}
            text={'Полностью настраиваемая система, устанавливается на сервер у любого хостера на ваше усмотрение или внутри локальной сети'}
          />
        </NavLink>
      </div>
      <div className={`${b24Styles['b24__form-container']}`}>
        <p className={`${appStyles['content__text']} ${appStyles['content__caption']} ${b24Styles['b24__content-caption']}`}>
          Записаться на бесплатную консультацию
        </p>
        <p className={`${appStyles['content__text']} ${appStyles['content__double']} ${b24Styles['b24__content-text']}`}>
          Оставьте контактные данные и мы обязательно <span className={`${b24Styles['b24__content-highlight']}`}>свяжемся с вами</span>.
        </p>
        <div className={`${b24Styles['b24__form']}`}>
          <script data-b24-form="inline/1/1typou" data-skip-moving="true">
            {b24form(window, document, 'https://cdn-ru.bitrix24.ru/b20630534/crm/form/loader_1.js')}
          </script>
        </div>
      </div>
    </Page>
  );
}

export default B24;
