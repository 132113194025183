import React from 'react';

import mainStyles from './notFound.module.css';

const NotFound = () => {
  return (
    <main className={mainStyles.main}>
      <div className={`${mainStyles['main__banner-back']}`}>
      </div>
      <div className={mainStyles.main__banner}>
        <div className={`${mainStyles['main__banner-content']}`}>
          <div className={`${mainStyles['main__banner-caption']}`}>
            <h2 className={`${mainStyles['main__banner-title']}`}>404</h2>
            <h2 className={`${mainStyles['main__banner-subtitle']}`}>Страница не найдена!</h2>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NotFound;