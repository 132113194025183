import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useNavigate } from 'react-router-dom';

import Blocks from 'editorjs-blocks-react-renderer';

import blogBackImage from '../../images/blo.jpg'
import recordImage from '../../images/fa.jpg'

import BlogPage from '../../components/blog-page/blog-page';
import RecordCard from '../../components/record-card/record-card';

import api from '../../utils/api';
import appStyles from '../../components/app/app.module.css'

const Blog = () => {
  const ALL = null;
  const NEWS = 'news';
  const ARTICLES = 'article';
  const PROMOS = 'promo';

  const navigate = useNavigate();
  const { link } = useParams();

  const [variant, setVariant] = useState(ALL);
  const [records, setRecords] = useState(null);
  const [record, setRecord] = useState(null);

  const recordImage = record?.image?.startsWith('/') ? (api.getImageRootUrl() + record?.image) : record?.image;
  const blogImage = (recordImage && recordImage !== '') ? recordImage : blogBackImage;

  const SimpleImage = ({
    data, className = ""
  }) => {
    const imageUrl = data?.url?.startsWith('/')
      ? api.getImageRootUrl() + data?.url
      : data?.url;

    return (
      <div className={`${appStyles['simple-image__container']}`}>
        <img className={`${appStyles['simple-image']}`} src={imageUrl} />
        <span className={`${appStyles['simple-image__caption']}`}>{data?.caption}</span>
      </div>
    )
   }

  useEffect(() => {
    if (!link) {
      setRecord(null);
    }

    getRecords(variant, link);
  }, [variant, link]);

  const getRecords = (type, link) => {
    api.getRecords(type, link)
      .then((res) => {
        if (link) {
          if (res.data.length === 0) {
            navigate("/404");
          }
          setRecords(null);
          setRecord(res.data[0]);
        }
        else {
          setRecords(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <BlogPage title={record?.title ?? 'Блог'} subtitle={record?.desc} backImage={blogImage} whiteBack={record}>
      <div className={`${appStyles['content__center']}`}>
        {!record && <div className={`${appStyles['selector']} ${appStyles['selector__blog']}`}>
          <p className={`${appStyles['selector__caption']}`}>&nbsp;</p>
          {false && record && <div className={`${appStyles['selector__container']}`}>
            <button className={`${appStyles['selector__button']}`} onClick={() => navigate("/blog")}>К другим {variant === ARTICLES ? "статьям" : variant === PROMOS ? "акциям" : "новостям"}</button>
          </div>}
          <div className={`${appStyles['selector__container']}`}>
            <button className={`${appStyles['selector__button']} ${variant === ALL && appStyles['selector__button-selected']}`} onClick={() => setVariant(ALL)}>Все</button>
            <button className={`${appStyles['selector__button']} ${variant === NEWS && appStyles['selector__button-selected']}`} onClick={() => setVariant(NEWS)}>Новости</button>
            <button className={`${appStyles['selector__button']} ${variant === ARTICLES && appStyles['selector__button-selected']}`} onClick={() => setVariant(ARTICLES)}>Статьи</button>
            <button className={`${appStyles['selector__button']} ${variant === PROMOS && appStyles['selector__button-selected']}`} onClick={() => setVariant(PROMOS)}>Акции</button>
          </div>
        </div>}
      </div>
      {records && <div className={`${appStyles['records']}`}>
        {records.length === 0 && <p className={`${appStyles['record-no']}`} >Пока ни одной записи...</p>}
        {records?.map((item) =>
          <NavLink key={item._id} className={`${appStyles['record-link']}`} to={item.link ?? ''}>
            <RecordCard
              date={item.updatedAt}
              title={<span>{item.title}</span>}
              desc={item.desc}
              image={item.image?.startsWith('/') ? (api.getImageRootUrl() + item.image) : item.image}
            />
          </NavLink>)}
      </div>}
      {record && <div className={`${appStyles['record-renderer']}`}>
        {
          record.body && <Blocks data={record.body} renderers={{
            simpleImage: SimpleImage
          }} />
        }
      </div>}
    </BlogPage>
  );
}

export default Blog;