import React, { useState } from 'react';
import Lottie from "lottie-react";

import cmsImage from '../../images/box.png'
import cmsBackImage from '../../images/fa.jpg'
import fireIcon from '../../images/fireIcon.json'
import usersIcon from '../../images/usersIcon.png'
import manyUsersIcon from '../../images/manyUsersIcon.png'

import Page from '../../components/page/page';
import PricingCard from '../../components/pricing-card/pricing-card';

import appStyles from '../../components/app/app.module.css';

const Cms = ({ orderClick }) => {
  const [usersNumber, setUsersNumber] = useState(50);

  return (
    <Page subtitle={'1С-Битрикс'} title={'Управление сайтом'} backImage={cmsBackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['caption']}`}>
          <p className={`${appStyles['caption__text']}`}>Выберите редакцию</p>
        </div>
      </div>
      <div className={`${appStyles['table']} ${appStyles['table__quatro']}`}>
        <PricingCard
          minWidth='240px'
          title={'Старт'}
          about={'«1С-Битрикс: Управление сайтом - Старт» — базовая лицензия, на основе которой можно создавать простые сайты, такие как: корпоративный сайт, интернет-витрина, лендинг, сайт-визитка.'}
          orderClick={orderClick}
          /*
          priceOld={'6 200'}
          priceCurrentNumber={'5 270'}
          */
          priceCurrentNumber={'6 200'}
          priceCurrentText={' ₽'}
          priceAbout={'лицензия'}
        >
        </PricingCard>
        <PricingCard
          minWidth='240px'
          title={'Стандарт'}
          about={'«1С-Битрикс: Управление сайтом - Стандарт» — популярная лицензия продукта, включающая все необходимые инструменты для управления интерактивным веб-проектом.'}
          orderClick={orderClick}
          /*
          priceOld={'17 900'}
          priceCurrentNumber={'15 215'}
          */
          priceCurrentNumber={'17 900'}
          priceCurrentText={' ₽'}
          priceAbout={'лицензия'}
        >
        </PricingCard>
        <PricingCard
          minWidth='240px'
          title={'Малый бизнес'}
          about={'«1С-Битрикс: Управление сайтом - Малый бизнес» — лицензия для создания интернет-магазина, с возможностью интеграции с 1С и CRM.'}
          orderClick={orderClick}
          /*
          priceOld={'40 900'}
          priceCurrentNumber={'34 765'}
          */
          priceCurrentNumber={'40 900'}
          priceCurrentText={' ₽'}
          priceAbout={'лицензия'}
          highlighted={true}
        >
        </PricingCard>
        <PricingCard
          minWidth='240px'
          title={'Бизнес'}
          about={'«1С-Битрикс: Управление сайтом - Бизнес» — лицензия расширенного интернет магазина включающая: неограниченное количество сайтов, рекламу и веб-аналитику, множество типов цен, накопительные скидки, многоскладовость.'}
          orderClick={orderClick}
          /*
          priceOld={'83 900'}
          priceCurrentNumber={'71 315'}
          */
          priceCurrentNumber={'83 900'}
          priceCurrentText={' ₽'}
          priceAbout={'лицензия'}
        >
        </PricingCard>
      </div>
    </Page>
  );
}

export default Cms;