import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useParallax } from 'react-scroll-parallax';

import b24CloudImage from '../../images/cloud.png'
import b24BoxImage from '../../images/box.png'
import busBoxImage from '../../images/yellowRocket.png'

import Minicard from '../../components/minicard/minicard';

import mainStyles from './main.module.css';
import appStyles from '../../components/app/app.module.css';
import { b24form } from '../../utils/b24';

const Main = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  const MOSCOW = 'moscow';
  const KALUGA = 'kaluga';

  const [variant, setVariant] = useState(MOSCOW);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const parallaxEnabled = windowDimensions.width > 760;

  const b24CloudParallax = useParallax({ speed: parallaxEnabled ? 2 : 1, translateX: [parallaxEnabled ? 2 : 0, 0] });
  const b24BoxParallax = useParallax({ translateY: [parallaxEnabled ? 105 : 0, parallaxEnabled ? 25 : 0], translateX: [parallaxEnabled ? -2 : 0, 0] });
  const cmsParallax = useParallax({ speed: parallaxEnabled ? 6 : 1, translateX: [parallaxEnabled ? 2 : 0, 0] });

  const bannerCaptionParallax = useParallax({ speed: parallaxEnabled ? 2 : 1 });
  const bannerImageParallax = useParallax({ speed: parallaxEnabled ? 42 : 28, rotate: [0, 24], translateX: ['-180px', '260px'] });

  const portfolioCardParallax = useParallax({ speed: 4, scale: [0.95, 1.05] });

  const feedbackFormParallax = useParallax({ speed: parallaxEnabled ? 6 : 1 });
  const feedbackImageParallax = useParallax({ speed: parallaxEnabled ? 8 : 1 });

  return (
    <main className={mainStyles.main}>
      <div className={mainStyles.main__banner}>
        <div className={`${mainStyles['main__banner-content']}`}>
          <div ref={bannerCaptionParallax.ref} className={`${mainStyles['main__banner-caption']}`}>
            <h2 className={`${mainStyles['main__banner-title']}`}>Разработка и сопровождение <br />web&#8209;проектов</h2>
          </div>
          <div ref={bannerImageParallax.ref} className={`${mainStyles['main__banner-image']}`}>
          </div>
        </div>
        <div className={`${mainStyles['main__banner-back']}`}>
        </div>
      </div>
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']}`}>
        <div className={mainStyles.main__content}>
          <p className={`${appStyles['content__text']}`}>
            Помогаем <span className={`${appStyles['content__highlight']}`}>автоматизировать</span> рутинные задачи, <span className={`${appStyles['content__highlight']}`}>повысить</span> эффективность бизнеса и контроль за показателями за счет внедрения <span className={`${appStyles['content__highlight']}`}>единой</span> информационной системы.
          </p>
          <br />
        </div>
      </div>
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']}`}>
        <div className={appStyles.minicards}>
          <NavLink ref={b24CloudParallax.ref} className={appStyles.cardLink} to="/b24/cloud">
            <Minicard
              title={<span>Битрикс24<br />Облачный</span>}
              image={b24CloudImage}
              text={'Гибкое управление всей структурой проекта и доступ к нему из любой точки мира, где есть интернет.'}
            />
          </NavLink>
          <NavLink ref={b24BoxParallax.ref} className={appStyles.cardLink} to="/b24/box">
            <Minicard
              title={<span>Битрикс24<br />Коробочный</span>}
              image={b24BoxImage}
              text={'Полностью настраиваемая система, устанавливается на сервер у любого хостера на ваше усмотрение или внутри локальной сети.'}
            />
          </NavLink>
          <NavLink ref={cmsParallax.ref} className={appStyles.cardLink} to="/cms">
            <Minicard
              title={<span>1С-Битрикс:<br />Управление сайтом</span>}
              image={busBoxImage}
              text={'Универсальный программный продукт для создания, поддержки и успешного развития сайтов и интернет-магазинов любого уровня.'}
            />
          </NavLink>
          {parallaxEnabled && <div className={`${mainStyles['main__cards-link']}`}></div>}
        </div>
      </div>
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-dark']}`}>
        {/*<div className={`${mainStyles['main__block-circle']} ${mainStyles['main__block-circle-one']}`} />*/}
        {/*<div className={`${mainStyles['main__block-circle']} ${mainStyles['main__block-circle-two']}`} />*/}
        {/*<div className={`${mainStyles['main__block-circle']} ${mainStyles['main__block-circle-three']}`} />*/}
        {/*<div className={`${mainStyles['main__block-circle']} ${mainStyles['main__block-circle-four']}`} />*/}
        {/*<NavLink className={`${mainStyles['main__navlink']}`} to="/cases">*/}
        <div className={`${mainStyles['main__navlink']}`}>
          <div ref={portfolioCardParallax.ref} className={`${mainStyles['main__content']} ${mainStyles['main__secondary']}`}>
            <div className={`${mainStyles['main__cases']}`}>
              <p className={`${mainStyles['main__secondary-text']}`}>
                Реализовали "с нуля" 28 проектов, 16 проектов с использованием готовых решений, а всего в нашей копилке <span className={`${mainStyles['main__secondary-highlight']}`}>более 79 реализованных кейсов</span>.
              </p>
              <div className={`${mainStyles['main__logos']}`}>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-ts']}`}>
                </div>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-osq']}`}>
                </div>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-mk']}`}>
                </div>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-cmg']}`}>
                </div>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-ub']}`}>
                </div>
                <div className={`${mainStyles['main__logo-container']} ${mainStyles['main__logo-gdc']}`}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']} ${mainStyles['main__block-overlayed']}`}>
        <div className={mainStyles.main__content}>
          <p className={`${mainStyles['main__content-text']}`}>
            Битрикс24 <span className={`${appStyles['content__highlight']}`}>эффективен</span> для любого бизнеса.
          </p>
          <br />
          <p className={`${mainStyles['main__content-text']}`}>
            С учетом ваших особенностей мы подберем оптимальное решение, <span className={`${appStyles['content__highlight']}`}>настроим</span> CRМ и <span className={`${appStyles['content__highlight']}`}>обучим</span> ваших сотрудников.
          </p>
          <br />
          <p className={`${mainStyles['main__content-text']}`}>
            Вы легко сможете ставить и проверять задачи, <span className={`${appStyles['content__highlight']}`}>автоматизировать</span> обработку лидов и осуществлять <span className={`${appStyles['content__highlight']}`}>контроль</span> менеджеров.
          </p>
          <br />
          <p className={`${mainStyles['main__content-text']}`}>
            Множество удобных инструментов помогут упростить работу, <span className={`${appStyles['content__highlight']}`}>повысить</span> клиентский сервис и в итоге значительно <span className={`${appStyles['content__highlight']}`}>улучшить</span> работу вашей компании.
          </p>
        </div>
      </div>
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']} ${mainStyles['main__block-bottom']}`}>
        <div className={`${mainStyles['main__block-cards']}`}>
          {/*        <div><a href="https://yandex.ru/maps/6/kaluga/?utm_medium=mapframe&utm_source=maps">Калуга</a><a href="https://yandex.ru/maps/6/kaluga/?from=techmapsmain&ll=36.261215%2C54.513845&utm_medium=mapframe&utm_source=maps&z=12">Яндекс.Карты — транспорт, навигация, поиск мест</a><iframe src="https://yandex.ru/map-widget/v1/-/CCUy7DeaxA" width="560" height="400" frameborder="1" allowFullScreen="true"></iframe></div>*/}
          <div ref={feedbackImageParallax.ref} className={`${mainStyles['main__block-back']}`}></div>
          <div className={`${mainStyles['main__block-double']}`}>
            <div ref={feedbackFormParallax.ref} className={`${mainStyles['main__block-column2']}`}>
              <div className={`${mainStyles['main__content']} ${mainStyles['main__feedback']}`}>
                <p className={`${appStyles['content__text']} ${appStyles['content__caption']}`}>
                  Обратная связь
                </p>
                <p className={`${appStyles['content__text']} ${appStyles['content__double']}`}>
                  Поделитесь мнением о нашей работе или задайте любой интересующий вас вопрос в поле <span className={`${mainStyles['main__content-highlight']}`}>комментарий</span>.
                </p>
                <script data-b24-form="inline/7/ovkwrm" data-skip-moving="true">
                  {b24form(window, document, 'https://cdn-ru.bitrix24.ru/b20630534/crm/form/loader_7.js')}
                </script>
              </div>
            </div>
            <div className={`${mainStyles['main__block-column']}`}>
              <div className={`${mainStyles['main__content']} ${mainStyles['main__feedback']} ${mainStyles['main__bro']}`}>
                <p className={`${appStyles['content__text']} ${mainStyles['main__content-caption']}`}>
                  Свяжитесь с нами
                </p>
                {false && <div className={`${appStyles['selector__container']}`}>
                  <button className={`${appStyles['selector__button']} ${variant == MOSCOW && appStyles['selector__button-selected']}`} onClick={() => setVariant(MOSCOW)}>Москва</button>
                  <button className={`${appStyles['selector__button']} ${variant == KALUGA && appStyles['selector__button-selected']}`} onClick={() => setVariant(KALUGA)}>Калуга</button>
                </div>}
                <iframe style={{ display: variant == MOSCOW ? 'block' : 'none' }} className={`${mainStyles['main__content']} ${mainStyles['main__feedback']} ${mainStyles['main__map']}`} src="https://yandex.ru/map-widget/v1/-/CCU5iKTEWA" allowFullScreen={true}></iframe>
                <iframe style={{ display: variant == KALUGA ? 'block' : 'none' }} className={`${mainStyles['main__content']} ${mainStyles['main__feedback']} ${mainStyles['main__map']}`} src="https://yandex.ru/map-widget/v1/-/CCUFNGTBTD" allowFullScreen={true}></iframe>
                {variant == MOSCOW
                  ? <p className={`${appStyles['content__text']} ${appStyles['content__contacts']}`}>
                    <span className={`${appStyles['content__annotation']}`}>Адрес</span><br />г. Москва,<br />Проектируемый проезд № 4062, д. 6, стр.16, бизнес-центр PortPlaza<br />
                    <span className={`${appStyles['content__annotation']}`}>Время работы</span><br />Пн-Пт: 10.00 — 20.00<br />
                    <span className={`${appStyles['content__annotation']}`}>Телефон</span><br /><a className={`${appStyles['content__simpleLink']}`} href="tel:+74993900455" nofollow="true">+7 499 390-04-55</a><br />
                    <span className={`${appStyles['content__annotation']}`}>Email</span><br /><a className={`${appStyles['content__simpleLink']}`} href="mailto:support@web-jet.ru" nofollow="true">support@web-jet.ru</a>
                  </p>
                  : <p className={`${appStyles['content__text']} ${appStyles['content__contacts']}`}>
                    <span className={`${appStyles['content__annotation']}`}>Адрес</span><br />г. Калуга,<br />ул. Суворова, д. 121, бизнес-центр Московский, офис 636<br />
                    <span className={`${appStyles['content__annotation']}`}>Телефон</span><br /><a className={`${appStyles['content__simpleLink']}`} href="tel:+74842230007" nofollow="true">+7 4842 23-00-07</a><br />
                    <span className={`${appStyles['content__annotation']}`}>Email</span><br /><a className={`${appStyles['content__simpleLink']}`} href="mailto:support@web-jet.ru" nofollow="true">support@web-jet.ru</a>
                  </p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {parallaxEnabled && <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']} ${mainStyles['main__block-overlayed']}`}></div>}
      <div className={`${mainStyles['main__block']} ${mainStyles['main__block-light']} ${mainStyles['main__block-semioverlayed']}`}></div>
    </main>
  );
}

export default Main;
