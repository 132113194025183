import React from 'react';
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";

import fireIcon from '../../images/fireIcon.json'
import faviconIcon from '../../images/faviconIcon.png'

import headerStyles from './header.module.css';

import Navigation from '../navigation/navigation';

const Header = ({ orderClick }) => {
  return (
    <header className={headerStyles.header}>
      <NavLink to="/" className={`${headerStyles.header__link}`} >
        {/*<Lottie className={`${headerStyles['header__icon']}`} animationData={fireIcon} loop={true} autoplay={true} />*/}
        {/*<img className={`${headerStyles['header__icon']}`} src={faviconIcon} />*/}
        <span className={`${headerStyles.header__logo}`} >webJet</span>
      </NavLink>
      <div className={`${headerStyles['header__phones']}`}>
        <a className={`${headerStyles['header__phone']} ${headerStyles['header__link']}`} href="tel:+74993900455" nofollow="true">+7 499 390-04-55</a>
      </div>
      <Navigation orderClick={orderClick} />
    </header>
  );
}

export default Header;