import React, { useState } from 'react';

import b24CloudBackImage from '../../images/sky.jpg'
import fireIcon from '../../images/fireIcon.json'
import usersIcon from '../../images/usersIcon.png'
import manyUsersIcon from '../../images/manyUsersIcon.png'
import augPromoImage from '../../images/promo_aug.png'

import Page from '../../components/page/page';
import PricingCard from '../../components/pricing-card/pricing-card';
import Calc from '../../components/calc/calc';

import appStyles from '../../components/app/app.module.css';

const B24Cloud = ({ orderClick }) => {
  const YEAR = 'year';
  const ONEMONTH = 'onemonth';
  const THREEMONTHS = 'threemonths';

  const rows = [
    {
      id: 1,
      name: "Написание технического задания по внедрению БИТРИКС24",
      about: "Заказчик вместе с Исполнителем утверждает итоговый функционал, архитектуру портала и перечень бизнес-процессов (Создание каталога товаров + складской учет, настройка сквозной аналитики, настройка CRM-маркетинга, настройка базы знаний, настройка роботов и триггеров, создание сайта и лендингов на платформе БИТРИКС24 и интеграция, создание интернет-магазина на платформе БИТРИКС24, интеграция и другие). Написание ТЗ включает в себя онлайн-конференции, встречи с Заказчиком. Финальная стоимость проекта будет основываться на техническом задании.",
      interval: 1,
      intervalText: "до 10 дней",
      price: 0,
      priceText: "Типовое — БЕСПЛАТНО, индивидуальное — 20000 ₽",
    },
    {
      id: 2,
      name: "Настройка структуры компании в Битрикс24. Добавление всех сотрудников в портал",
      about: `Настройка структуры компании в Битрикс24,
        добавление сотрудников и распределение по 
        структуре.Для реализации необходимы будут 
        заполненные анкеты сотрудников (ФИО, должность, контактные данные, прочее). Предоставляются Исполнителем после ввода 
        проекта в работу.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽",
    },
    {
      id: 3,
      name: "Настройка ролей и прав доступа к элементам CRM",
      about: `Настройка ролей для сотрудников и распределение
        прав доступа (Нет доступа, Чтение, Редактирование,
        Удаление) для элементов CRM согласно структуре
        компании (Отделы, Подотделы). Защита базы от
        копирования.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 4,
      name: "Настройка сущностей CRM (Лиды, Контакты, Компании, Сделки)",
      about: `Настройка пользовательских полей в четырех
        основных сущностях CRM: Лиды, Контакты,
        Компании, Сделки. Добавление новых полей
        только в рамках штатного функционала
        Битрикс24.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 5,
      name: "Настройка воронки продаж",
      about: `Добавление и редактирование этапов в
        воронках продаж (Сделки) и воронках
        лидов/заказов`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 6,
      name: "Перенос базы данных по контактам, компаниям и лидам в БИТРИКС24",
      about: `Импорт лидов, контактов, компаний, сделок и товаров. Для импорта элементов используется формат csv.`,
      interval: 1,
      intervalText: "1-2 дня",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 7,
      name: "Интеграция CRM с электронной почтой",
      about: `После данной интеграции сотрудники смогут
        отправлять письма из CRM системы, все
        сообщения, которые отправляются клиентам,
        будут храниться в карточках клиентов. Так же
        вы сможете делать e-mail рассылку.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 8,
      name: "Интеграция Битрикс24 с Телефонией",
      about: `Настройка телефонии в Битрикс24 с
        возможностью фиксации всех входящих и
        исходящих звонков, настройка распределения
        звонков по ответственным лицам, возможность
        пользоваться через мобильное приложение
        Битрикс24.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 9,
      name: "Интеграция с социальными сетями",
      about: `Подключение инструментов контакт-центра: онлайн-чат и форма
        обратной связи/обратного звонка на сайте, обработка
        обращений из WhatsApp, Вконтакте, комментарии к постам
        Instagram, Telegram, настройка актуальных инструментов контакт-центра.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 10,
      name: "Интеграция с сайтом",
      about: `Подключение открытых линий и настройка
        распределения заявок. Добавление виджета на сайт.
        Подключение CRM-форм, настройка, сопоставления полей, проверка
        работоспособности`,
      interval: 2,
      intervalText: "2 дня",
      price: 12000,
      priceText: "12000 ₽"
    },
    {
      id: 11,
      name: "Интеграция с 1С УТ.БП, УНФ (1С: Бэкофис 2.0)",
      about: `Интеграция с 1С в рамках штатного модуля
        обмена. Штатный модуль имеет следующие
        возможности:
        1. Автоматическая и по расписанию
        Синхронизация содержимого и
        статусов Сделок и Заказов между 1С и
        Битрикс24
        2. Работа с 1С внутри Битрикс24 по
        принципу одного окна
        3. Создавать клиентов в Битрикс24 с
        автоматической синхронизацией с 1С
        4. Выбирать и искать клиентов в 1С
        прямо из поисковой строки Битрикс24
        5. Звонки из 1С через Битрикс24
        6. Проверять надѐжность контрагентов
        через 1С:Спарк
        7. Обмен между 1С и Битрикс24 в режиме
        реального времени
        8. Автоматизация рутинных задач с 1С
        при помощи Роботов и Триггеров.`,
      interval: 10,
      intervalText: "10 дней",
      price: 30000,
      priceText: "30000 ₽"
    },
    {
      id: 12,
      name: "Подключение онлайн-кассы, CRM-Доставка, настройка и интеграция",
      about: `Подключение сервисов: SMS-Провайдер,Платежная система, касса`,
      interval: 3,
      intervalText: "3 дня",
      price: 6000,
      priceText: "6000 ₽"
    },
    {
      id: 13,
      name: "Установка и настройка приложений из Битрикс24.Маркет Плюс",
      about: `Каталог приложений – Битрикс24.Маркет, который расширяет возможности интеграций с внешними системами, дополняет готовые возможности Битрикс24 новыми бизнес-сценариями, автоматизирует работу в системе, упрощает настройки, предлагая шаблоны CRM, сайтов и процессов.`,
      interval: 1,
      intervalText: "1 день",
      price: 0,
      priceText: "БЕСПЛАТНО в составе проекта"
    },
    {
      id: 14,
      name: "Настройка печатных форм документов  в БИТРИКС24",
      about: `Настройка шаблонов договора, счета и других документов.`,
      interval: 1,
      intervalText: "1 день",
      price: 6000,
      priceText: "До 5 шт. — 6000 ₽"
    },
    {
      id: 15,
      name: "Обучение сотрудников, по работе с корпоративным порталом",
      about: `Обучим пользователей основам работы в
        корпоративном портале. Мы предоставляем
        регламент и методические пособия по работе с
        настроенным порталом. Обучение будем
        производить по Skype/Zoom.`,
      interval: 1,
      intervalText: "1 день",
      price: 0,
      priceText: "БЕСПЛАТНО 2 часа, далее 1 час — 3000 ₽"
    },
  ];

  const enterpricePrices = {
    250: {
      [YEAR]: { priceOld: '33 990', priceCurrentNumber: '27 190' },
      [THREEMONTHS]: { priceCurrentNumber: '33 990' },
      [ONEMONTH]: { priceCurrentNumber: '33 990' },
    },
    500: {
      [YEAR]: {priceOld: '59 990', priceCurrentNumber: '47 990' },
      [THREEMONTHS]: { priceCurrentNumber: '59 990' },
      [ONEMONTH]: { priceCurrentNumber: '59 990' },
    },
    1000: {
      [YEAR]: {priceOld: '99 990', priceCurrentNumber: '79 990' },
      [THREEMONTHS]: { priceCurrentNumber: '99 990' },
      [ONEMONTH]: { priceCurrentNumber: '99 990' },
    },
    2000: {
      [YEAR]: {priceOld: '199 990', priceCurrentNumber: '159 990' },
      [THREEMONTHS]: { priceCurrentNumber: '199 990' },
      [ONEMONTH]: { priceCurrentNumber: '199 990' },
    },
    3000: {
      [YEAR]: {priceOld: '299 990', priceCurrentNumber: '239 990' },
      [THREEMONTHS]: { priceCurrentNumber: '299 990' },
      [ONEMONTH]: { priceCurrentNumber: '299 990' },
    },
    4000: {
      [YEAR]: {priceOld: '399 990', priceCurrentNumber: '319 990' },
      [THREEMONTHS]: { priceCurrentNumber: '399 990' },
      [ONEMONTH]: { priceCurrentNumber: '399 990' },
    },
    5000: {
      [YEAR]: {priceOld: '499 990', priceCurrentNumber: '399 990' },
      [THREEMONTHS]: { priceCurrentNumber: '499 990' },
      [ONEMONTH]: { priceCurrentNumber: '499 990' },
    },
    6000: {
      [YEAR]: {priceOld: '599 990', priceCurrentNumber: '479 990' },
      [THREEMONTHS]: { priceCurrentNumber: '599 990' },
      [ONEMONTH]: { priceCurrentNumber: '599 990' },
    },
    7000: {
      [YEAR]: {priceOld: '699 990', priceCurrentNumber: '559 990' },
      [THREEMONTHS]: { priceCurrentNumber: '699 990' },
      [ONEMONTH]: { priceCurrentNumber: '699 990' },
    },
    8000: {
      [YEAR]: {priceOld: '799 990', priceCurrentNumber: '639 990' },
      [THREEMONTHS]: { priceCurrentNumber: '799 990' },
      [ONEMONTH]: { priceCurrentNumber: '799 990' },
    },
    9000: {
      [YEAR]: {priceOld: '899 990', priceCurrentNumber: '719 990' },
      [THREEMONTHS]: { priceCurrentNumber: '899 990' },
      [ONEMONTH]: { priceCurrentNumber: '899 990' },
    },
    10000: {
      [YEAR]: {priceOld: '999 990', priceCurrentNumber: '799 990' },
      [THREEMONTHS]: { priceCurrentNumber: '999 990' },
      [ONEMONTH]: { priceCurrentNumber: '999 990' },
    },
  };

  const [variant, setVariant] = useState(YEAR);
  const [promoOn, setPromoOn] = useState(false);
  const [usersNumber, setUsersNumber] = useState(250);

  return (
    <Page subtitle={'Битрикс24'} title={'Облачный'} backImage={b24CloudBackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['selector']}`}>
          <p className={`${appStyles['selector__caption']}`}>Цена за месяц при покупке</p>
          <div className={`${appStyles['selector__container']}`}>
            <button className={`${appStyles['selector__button']} ${variant === ONEMONTH && appStyles['selector__button-selected']}`} onClick={() => setVariant(ONEMONTH)}>на 1 месяц</button>
            <button className={`${appStyles['selector__button']} ${variant === THREEMONTHS && appStyles['selector__button-selected']}`} onClick={() => setVariant(THREEMONTHS)}>на 3 месяца</button>
            <button className={`${appStyles['selector__button']} ${variant === YEAR && appStyles['selector__button-selected']}`} onClick={() => setVariant(YEAR)}>на год<span className={`${appStyles['selector__button-discount']}`}>{promoOn ? '-30%' : '-20%'}</span></button>
          </div>
        </div>
      </div>
      {false &&
      <div className={`${appStyles['promo__container']}`}>
        <div className={`${appStyles['selector__promo']}`}>
          <div className={`${appStyles['selector__container']} ${appStyles['selector__promo-container']}`}>
            <button className={`${appStyles['selector__button']} ${promoOn && appStyles['selector__button-green']}`} onClick={() => setPromoOn(true)}>Для новых клиентов</button>
            <button className={`${appStyles['selector__button']} ${!promoOn && appStyles['selector__button-green']}`} onClick={() => setPromoOn(false)}>Для тех, кто уже покупал тариф</button>
          </div>
        </div>
        {promoOn && <img className={`${appStyles['promo__banner']}`} src={augPromoImage} alt={'Акция до 31 августа'} />}
      </div>}
      <div className={`${appStyles['table']} ${appStyles['table__five']}`}>
        <PricingCard
          title={'Бесплатный'}
          about={'Начните работать онлайн и продавать больше с CRM'}
          orderClick={() => window.open("https://www.bitrix24.ru/create.php?p=15284846", "_blank")}
          usersAnimatedIcon={fireIcon}
          usersNumber={'Неограниченно'}
          usersNoun={'пользователей'}
          priceOld={variant === YEAR && '\u00A0'}
          priceCurrentNumber={'Бесплатно'}
          priceAbout={'\u00A0'}
          volume={'5 Гб'}
          buttonText={'Создать'}
          isGreen={true}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Совместная работа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
              <ul className={`${appStyles['table__card-sublist']}`}>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Чат</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Видеозвонки HD</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Календарь</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Соцсеть компании</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Новости</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>База знаний</span></li>
              </ul>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Задачи и Проекты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>CRM</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Диск</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Контакт-центр</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сайты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
          </ul>
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Поддержка</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelGreen']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>&nbsp;</span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Базовый'}
          about={'Для эффективной работы небольших компаний и отделов продаж'}
          orderClick={orderClick}
          usersIcon={usersIcon}
          usersNumber={5}
          usersNoun={'пользователей'}
          priceOld={
            variant === YEAR
              ? '2 490'
              : null
          }
          priceCurrentNumber={
            variant === YEAR
              ? (promoOn ? '1 743' : '1 990')
              : '2 490'
          }
          priceCurrentText={' ₽/мес.'}
          priceDiscount={
            variant === YEAR
              ? (promoOn ? '-30%' : '-20%')
              : null
          }
          priceAbout={'за всех пользователей'}
          volume={'24 Гб'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Совместная работа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
              <ul className={`${appStyles['table__card-sublist']}`}>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Чат</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Видеозвонки HD</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Календарь</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Соцсеть компании</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Новости</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>База знаний</span></li>
              </ul>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Задачи и Проекты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>CRM</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Диск</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Контакт-центр</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сайты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Интернет-магазин</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
          </ul>
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Поддержка</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelLow']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>&nbsp;</span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Стандартный'}
          about={'Для совместной работы всей компании или рабочих групп'}
          orderClick={orderClick}
          usersIcon={manyUsersIcon}
          usersNumber={50}
          usersNoun={'пользователей'}
          priceOld={
            variant === YEAR
              ? '6 990'
              : null
          }
          priceCurrentNumber={
            variant === YEAR
              ? (promoOn ? '4 893' : '5 590')
              : '6 990'
          }
          priceCurrentText={' ₽/мес.'}
          priceDiscount={
            variant === YEAR
              ? (promoOn ? '-30%' : '-20%')
              : null
          }
          priceAbout={'за всех пользователей'}
          volume={'100 Гб'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Совместная работа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
              <ul className={`${appStyles['table__card-sublist']}`}>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Чат</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Видеозвонки HD</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Календарь</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Соцсеть компании</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Новости</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>База знаний</span></li>
              </ul>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Задачи и Проекты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>CRM</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Диск</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Контакт-центр</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сайты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Интернет-магазин</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Маркетинг</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Документы Онлайн</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
          </ul>
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Поддержка</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Администрирование</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Профессиональный'}
          about={'Для максимальной автоматизации всех процессов в компании'}
          orderClick={orderClick}
          usersIcon={manyUsersIcon}
          usersNumber={100}
          usersNoun={'пользователей'}
          priceOld={
            variant === YEAR
              ? '13 990'
              : null
          }
          priceCurrentNumber={
            variant === YEAR
              ? (promoOn ? '9 793' : '11 190')
              : '13 990'
          }
          priceCurrentText={' ₽/мес.'}
          priceDiscount={
            variant === YEAR
              ? (promoOn ? '-30%' : '-20%')
              : null
          }
          priceAbout={'за всех пользователей'}
          volume={'1024 Гб'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Совместная работа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
              <ul className={`${appStyles['table__card-sublist']}`}>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Чат</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Видеозвонки HD</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Календарь</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Соцсеть компании</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Новости</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>База знаний</span></li>
              </ul>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Задачи и Проекты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>CRM</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Диск</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Контакт-центр</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сайты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Интернет-магазин</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Маркетинг</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Документы Онлайн</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сквозная аналитика</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Автоматизация<br/>Бизнеса</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>HR</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
          </ul>
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Поддержка</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Администрирование</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelMid']}`}></span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Энтерпрайз'}
          about={'Для цифровизации крупного и среднего бизнеса на максимальных скоростях'}
          orderClick={orderClick}
          usersIcon={manyUsersIcon}
          usersNumber={usersNumber}
          usersNumbers={[250, 500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000]}
          usersNumberClick={setUsersNumber}
          usersNoun={'пользователей'}
          priceOld={usersNumber && variant && enterpricePrices[usersNumber][variant].priceOld}
          priceCurrentNumber={usersNumber && variant && enterpricePrices[usersNumber][variant].priceCurrentNumber}
          priceCurrentText={' ₽/мес.'}
          priceDiscount={
            variant === YEAR
              ? (promoOn ? '-30%' : '-20%')
              : null
          }
          priceAbout={'за всех пользователей'}
          volume={'3 Тб'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Совместная работа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
              <ul className={`${appStyles['table__card-sublist']}`}>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Чат</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Видеозвонки HD</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Календарь</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Соцсеть компании</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>Новости</span></li>
                <li><span className={`${appStyles['table__card-sublistItem']}`}>База знаний</span></li>
              </ul>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Задачи и Проекты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>CRM</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Диск</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Контакт-центр</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сайты</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Интернет-магазин</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Маркетинг</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Документы Онлайн</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Сквозная аналитика</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Автоматизация<br />Бизнеса</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>HR</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Филиалы</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Энтерпрайз-кластер</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Энтерпрайз-пакет</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
          </ul>
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Поддержка</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Администрирование</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelHigh']}`}></span>
            </li>
          </ul>
        </PricingCard>
      </div>
      <Calc data={rows} />
    </Page>
  );
}

export default B24Cloud;