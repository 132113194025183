import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import recordCardStyles from './record-card.module.css';

const RecordCard = ({ date, title, desc, image }) => {
  const dateSrc = new Date(date).toLocaleString('ru-RU', { year: 'numeric', month: 'numeric', day: 'numeric' });
  const dateDst = dateSrc.split(".").join(".");

  return (
    <div className={`${recordCardStyles['record-card']}`}>
      <p className={`${recordCardStyles['record-card__title']}`}>{title}</p>
      <p className={`${recordCardStyles['record-card__text']}`}>{desc}</p>
      {image && <img className={`${recordCardStyles['record-card__image']}`} src={image} alt={title} />}
      <div className={`${recordCardStyles['record-card__footer']}`}>
        <p className={`${recordCardStyles['record-card__link']}`}>Подробнее</p>
        <p className={`${recordCardStyles['record-card__date']}`}>{dateDst}</p>
      </div>
    </div>
  )
}

export default RecordCard;