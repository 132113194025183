import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import blogPageStyles from './blog-page.module.css';

const BlogPage = ({ title, subtitle, image, backImage, whiteBack, children }) => {

  return (
    <main className={blogPageStyles['blog-page']}>
      <div style={{ backgroundImage: 'url(' + backImage + ')' }} className={blogPageStyles['blog-page__banner']}>
        <div className={`${blogPageStyles['blog-page__banner-content']}`}>
          <div className={`${blogPageStyles['blog-page__banner-caption']}`}>
            <h2 className={`${blogPageStyles['blog-page__banner-title']}`}>{title}</h2>
            <h2 className={`${blogPageStyles['blog-page__banner-subtitle']}`}>{subtitle}</h2>
          </div>
          <div style={{ backgroundImage: 'url(' + image + ')' }} className={`${blogPageStyles['blog-page__banner-image']}`}>
          </div>
        </div>
      </div>
      <div className={`${blogPageStyles['blog-page__content']}  ${whiteBack && blogPageStyles['blog-page__content__record']}`}>
      {children}
      </div>
    </main>
  );
}

export default BlogPage;