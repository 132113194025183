import React, { useState } from 'react';
import timeIcon from '../../images/timeIcon.svg'
import sortIcon from '../../images/sortIcon.png'

import calcRowStyles from './calcRow.module.css';

const CalcRow = ({ data, onClick, expanded, onCheck, checked, price, interval, isTotal }) => {

  return (
    <div className={`${calcRowStyles.calcRow} ${checked && calcRowStyles.selected}`}>
      <div className={calcRowStyles.id}>
        {data?.id}
      </div>
      <div className={calcRowStyles.content}>
        <div className={calcRowStyles.caption} onClick={(e) => data?.about ? onClick(e) : {}}>
          <div className={`${calcRowStyles.name} ${data?.about && calcRowStyles.pointer}`}>
            <span>{data?.name}</span>
            {data?.about && <img className={`${calcRowStyles.sortIcon} ${expanded && calcRowStyles.sortIconExpanded}`} src={sortIcon} alt="Информация" />}
          </div>
          <div className={`${calcRowStyles.about} ${expanded && calcRowStyles.aboutExpanded}`}>
            {data?.about}
          </div>
          {isTotal && <div className={calcRowStyles.name}>
            Итого
          </div>}
        </div>
        <div className={calcRowStyles.info}>
          <div className={calcRowStyles.interval}>
            <img className={calcRowStyles.timeIcon} src={timeIcon} alt="Время" />
            {data?.intervalText ?? (interval > 0 ? <span className={calcRowStyles.total}>от {interval} {(interval.toString().endsWith("1") && !interval.toString().endsWith("11")) ? "дня" : "дней"}</span> : "")}
          </div>
          <div className={calcRowStyles.price}>
            {data?.priceText ? <span className={calcRowStyles.priceText}>{data?.priceText}</span> : (price > 0 ? <span className={calcRowStyles.total}>от {price} ₽</span> : "")}
          </div>
        </div>
      </div>
      <div className={calcRowStyles.selector}>
        {!isTotal && <div className={calcRowStyles.cb} onClick={onCheck}>
          {checked && <div className={calcRowStyles.cbInner} />}
        </div>}
      </div>
    </div>
  )
}

export default CalcRow;