import React from "react";
import styles from "./button.module.css";
import buttonDesign from "../../constants/button-design";

const Button = ({
  text,
  extraClass = "",
  type = "button",
  isLoader = false,
  disabled,
  design = buttonDesign.BLUE,
  ...rest
}) => {
  const className = `${
    styles.button
  } ${
    isLoader && styles.loader
  } ${extraClass} ${design === buttonDesign.GREEN ? styles.greenButton : design === buttonDesign.BLUE_OUTLINE ? styles.blueOutlineButton : styles.blueButton }`;

  return (
    <button
      className={className}
      type={type}
      disabled={isLoader || disabled}
      {...rest}
    >
      <p className={`text text_type_button ${design === buttonDesign.BLUE_OUTLINE ? "text_color_blue" : "text_color_light" }`}>{text}</p>
    </button>
 )
};

export default Button;