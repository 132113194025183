import React from 'react';
import { NavLink } from 'react-router-dom';
import appStyles from '../app/app.module.css';
import Button from '../../ui/button/button';

import authFormStyles from './auth-form.module.css';

const AuthForm = ({ name, title, bottomText, buttonText, onSubmit, children }) => {
  return (
    <section className={`${authFormStyles.section}`}>
      <form className={`${authFormStyles.form}`} name={name} onSubmit={onSubmit}>
        <h2 className={`${authFormStyles.title}`}>{title}</h2>
        {children}
        <div className={`${authFormStyles.button}`}><Button type="submit" text={buttonText ?? "���������"} /></div>
        {bottomText}
      </form>
    </section>
  );
}

export default AuthForm;