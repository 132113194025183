import React from 'react';
import { NavLink } from 'react-router-dom';
import appStyles from '../app/app.module.css';
import b24AboutStyles from './b24-about.module.css';
import buttonDesign from '../../constants/button-design';

import crm2021 from '../../images/b24-about-crm-2021.png';
import b24AboutMain from '../../images/b24-about-main.png';
import b24AboutTasks from '../../images/b24-about-tasks.png';
import b24AboutCRM from '../../images/b24-about-crm.png';
import b24AboutAutomation from '../../images/b24-about-automation.png';
import b24AboutContact from '../../images/b24-about-contact.png';
import b24AboutSites from '../../images/b24-about-sites.jpeg';
import b24AboutSocial from '../../images/b24-about-social.png';
import b24AboutMobile from '../../images/b24-about-mobile.png';
import b24AboutBox from '../../images/b24-about-box-block.png';

import Button from '../../ui/button/button';

const B24About = ({ orderClick }) => {
  return (
    <div className={`${appStyles['content__narrow']} ${appStyles['content__padding']} ${b24AboutStyles['b24articles']}`}>
      <a className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']} ${b24AboutStyles['b24article']}`} href="https://www.bitrix24.ru/create.php?p=15284846" target="_blank" rel="noreferrer">
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Что такое Битрикс24?</h3>
          <p className={`${b24AboutStyles['article__text']}`}>Битрикс24 – это набор полезных инструментов, которые помогают бизнесу работать: CRM, Видеозвонки, Документы Онлайн, Задачи и проекты, Контакт-центр, конструктор сайтов и Интернет-магазины.</p>
          <Button text="Создать бесплатно" design={buttonDesign.GREEN} />
        </div>
        <img src={crm2021} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24" />
      </a>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <img src={b24AboutMain} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24" />
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Совместная работа</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Набор онлайн-инструментов</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Битрикс24 помогает руководителю выстроить в своём коллективе эффективную работу.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Мгновенно выходите на связь с любым сотрудником по видеосвязи и в чатах, редактируйте документы, общайтесь с коллегами в новостной ленте и группах.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Календарь поможет спланировать встречи, почта — собрать все письма в одном окне, а диск — хранить файлы внутри компании.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Узнать больше о Cовместной работе</a></span>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Задачи и проекты</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Помогают работать вместе и успевать вовремя </p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Ставьте задачи в Битрикс24, и они будут выполнены.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Используйте разные методики планирования как задач, так и проектов: Сроки, Мой план, диаграмма Ганта, слоты в календаре. Или организуйте работу по правилам Скрам, используя канбан-доску и другие готовые инструменты.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Узнать больше о задачах</a></span>
        </div>
        <img src={b24AboutTasks} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Задачи" />
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <img src={b24AboutCRM} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Задачи" />
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>CRM</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Помогает продавать больше</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>CRM ведет учет клиентской базы, анализирует данные, подсказывает менеджерам, что делать дальше, берет на себя рутинные задачи.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Для руководителя — это контроль и полная прозрачность в продажах. Для сотрудника — это удобный инструмент, который в разы упрощает отчетность, обработку заказов и работу с клиентами.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Подробнее о возможностях CRM</a></span>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Автоматизация бизнеса</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Забудьте о рутине</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Автоматизируйте бизнес-процессы, чтобы заниматься действительно важными делами. Упростите согласование счетов, отпусков, командировок с помощью конструктора бизнес-процессов.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>А простые повторяющиеся задачи выполнят роботы — вовремя отправят письмо, ссылку на оплату клиенту, добавят или удалят товарную позицию, поставят задачу менеджеру.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Подробнее об автоматизации</a></span>
        </div>
        <img src={b24AboutAutomation} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Автоматизация бизнеса" />
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <img src={b24AboutContact} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Контакт-центр" />
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Контакт-центр</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Помогает любить клиента</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Подключите всевозможные каналы связи к Битрикс24: телефон, e-mail, ВКонтакте, WhatsApp, Telegram, чат на сайте и другие. Вы разделите весь поток обращений между менеджерами, а клиенты продолжат общаться там, где удобно им.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Контакты, вся переписка и записи разговоров с клиентами сохранятся в CRM.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Подробнее о Контакт-центре</a></span>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Сайты</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Помогают продавать</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Неоспоримое преимущество конструктора сайтов и магазинов Битрикс24 то, что он создаёт их уже внутри CRM. Контакты покупателей магазина и клиентов сайта, обратившихся к вам через CRM-форму, бесплатный чат или заказавшие обратный звонок, сразу попадают в CRM.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Подключите Яндекс.Метрика и Google.Analytics к сайту в один клик и получайте полную статистику по посетителям.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Узнать больше о Сайтах.Битрикс24</a></span>
        </div>
        <img src={b24AboutSites} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Сайты" />
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <img src={b24AboutSocial} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Интернет-магазины" />
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Интернет-магазины</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Для продаж в соцсетях</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Создайте адаптивный магазин за 2 минуты с витриной товаров из вашей CRM.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Опубликуйте ссылку на интернет-магазин в соцсетях, отправьте ссылку клиенту в WhatsApp или SMS!</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Узнать больше об Интернет-магазинах</a></span>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Мобильное приложение</h3>
          <p className={`${b24AboutStyles['article__subtitle']}`}>Помогает оставаться на связи</p>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Не каждому сотруднику для работы нужен компьютер — кто-то работает «в полях», на складе или в доставке. С ними также важно поддерживать связь, обсуждать рабочие вопросы и, конечно же, ставить и контролировать задачи.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Вы всегда остаётесь в курсе событий и продолжаете выполнять свою работу, даже вне офиса.</p>
          </div>
          <span><a className={`${b24AboutStyles['article__link']}`} href="#" onClick={orderClick}>Подробнее о приложении Битрикс24</a></span>
        </div>
        <img src={b24AboutMobile} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Мобильное приложение" />
      </div>
      <div className={`${appStyles['content__narrow']} ${appStyles['minicards']} ${b24AboutStyles['article']}`}>
        <img src={b24AboutBox} className={`${b24AboutStyles['article__image']}`} alt="Скриншот Б24. Коробочная версия" />
        <div className={`${b24AboutStyles['article__description']}`}>
          <h3 className={`${b24AboutStyles['article__title']}`}>Коробочная версия</h3>
          <div className={`${b24AboutStyles['article__text']}`}>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Разместите Битрикс24 на сервере компании или в датацентре, и управляйте скоростью и надежностью его работы. Вы можете использовать несколько серверов, тем самым обеспечив высокую доступность портала и его масштабирование.</p>
            <p className={`${b24AboutStyles['article__paragraph']}`}>Открытый исходный код позволяет дорабатывать интерфейс и функциональность Битрикс24 под индивидуальные потребности и бизнес-логику вашей компании без ограничений.</p>
          </div>
          <div className={`${b24AboutStyles['article__buttons-container']}`}>
            <NavLink className={`${b24AboutStyles['article__link']} ${b24AboutStyles['article__link_type_button']}`} to="/b24/box">
              <Button extraClass={`${b24AboutStyles['article__button']}`} text="Выбрать и купить" design={buttonDesign.BLUE} />
            </NavLink>
            <a className={`${b24AboutStyles['article__link']} ${b24AboutStyles['article__link_type_button']}`} onClick={orderClick}>
              <Button extraClass={`${b24AboutStyles['article__button']}`} text="Узнать больше" design={buttonDesign.BLUE_OUTLINE} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default B24About;