import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Lottie from "lottie-react";

import pricingCardStyles from './pricing-card.module.css';

const PricingCard = ({ isGreen, minWidth, title, about, orderClick, usersIcon, usersAnimatedIcon, usersNumber, usersNumbers, usersNumberClick, usersNoun, priceOld, priceCurrentNumber, priceCurrentText, priceAbout, priceDiscount, volume, buttonText, highlighted, children }) => {

  return (
    <div style={{ minWidth: minWidth }} className={`${pricingCardStyles['pricingCard']} ${highlighted && pricingCardStyles['pricingCard_highlighted']}`}>
      <div className={`${pricingCardStyles['pricingCard-header']}`}>
        <h2 className={`${pricingCardStyles['pricingCard-title']}`}>{title}</h2>
        <p className={`${pricingCardStyles['pricingCard-about']}`}>{about}</p>
      </div>
      <div className={`${pricingCardStyles['pricingCard-block']}`}>
        <div className={`${pricingCardStyles['pricingCard-price']}`}>
          <p className={`${pricingCardStyles['pricingCard-priceOld']}`}>{priceOld}</p>
          <p className={`${isGreen ? pricingCardStyles['pricingCard-priceCurrentGreen'] : pricingCardStyles['pricingCard-priceCurrent']}`}>
            <span className={`${pricingCardStyles['pricingCard-priceCurrentNumber']}`}>{priceCurrentNumber}</span>
            <span className={`${pricingCardStyles['pricingCard-priceCurrentText']}`}>{priceCurrentText}</span>
            {priceDiscount && <span className={`${pricingCardStyles['pricingCard-priceCurrentDiscount']}`}>{priceDiscount}</span>}
          </p>
          <p className={`${pricingCardStyles['pricingCard-priceAbout']}`}>{priceAbout}</p>
        </div>
        {usersNumber && <div className={`${pricingCardStyles['pricingCard-usersContainer']}`}>
          {usersAnimatedIcon && <Lottie className={`${pricingCardStyles['pricingCard-animatedIcon']}`} animationData={usersAnimatedIcon} loop={true} autoplay={true} />}
          {false && usersIcon && <img className={`${pricingCardStyles['pricingCard-icon']}`} src={usersIcon} alt="Icon" />}
          <p className={`${pricingCardStyles['pricingCard-users']}`}>
            <div className={`${pricingCardStyles['pricingCard-usersNumbers']}`}>
              {!usersNumbers && <span className={`${pricingCardStyles['pricingCard-usersNumber']}  ${usersNumber && isNaN(usersNumber) && pricingCardStyles['pricingCard-usersNumber__nan']}`}>{usersNumber}</span>}
              {usersNumbers && usersNumbers.length <= 4 && usersNumbers.map((item, index) => <span key={index} className={`${pricingCardStyles['pricingCard-usersNumber']} ${pricingCardStyles['pricingCard-usersNumberSelectable']} ${usersNumber == item && pricingCardStyles['pricingCard-usersNumberSelected']}`} onClick={() => usersNumberClick(item)}>{item}</span>)}
              {usersNumbers && usersNumbers.length > 4 && <select className={`${pricingCardStyles['pricingCard-usersNumber-selector']}`} value={usersNumber} onChange={(e) => usersNumberClick(e.target.value)}>{usersNumbers.map((item, index) => <option key={index} className={`${pricingCardStyles['pricingCard-usersNumber']} ${pricingCardStyles['pricingCard-usersNumberSelectable']} ${usersNumber == item && pricingCardStyles['pricingCard-usersNumberSelected']}`} value={item}>{item}</option>)}</select>}
            </div>
            {usersNoun}
          </p>
        </div>}
        {volume && <p className={`${pricingCardStyles['pricingCard-volume']}`}>{volume}</p>}
        <div className={`${pricingCardStyles['pricingCard-buttonContainer']}`}>
          <div className={isGreen ? `${pricingCardStyles['pricingCard-buttonGreen']}` : `${pricingCardStyles['pricingCard-button']}`} onClick={orderClick}>
            {buttonText ?? 'Купить'}
          </div>
        </div>
      </div>
      {children && <div className={`${pricingCardStyles['pricingCard-list']}`}>
        {children}
      </div>}
    </div>
  )
}

export default PricingCard;