import React from 'react';

import casesImage from '../../images/window.png'

import Page from '../page/page';

const Cases = () => {
  return (
    <Page title={'Портфолио'} image={casesImage}>
      <p>
        Мы занимаемся самыми разнообразными задачами в web-разработке, вот уже 15 лет. Сейчас все наши проекты выходят на базе платформы 1С-Битрикс. Мы используем полный инструментарий этой компании: управление сайтом, CRM в облаке и коробочное решение, мобильное приложение. Так же у нас есть проверенные специалисты в 1С и поисковом продвижении. Мы реализовали с "нуля" 28 проектов, с использованием готовых решений - 16 проектов, а всего в нашей копилке более 79 реализованных кейсов.
      </p>
    </Page>
  );
}

export default Cases;