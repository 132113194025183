import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import minicardStyles from './minicard.module.css';

const Minicard = ({ title, image, text }) => {

  return (
    <div className={minicardStyles.minicard}>
      <p className={`${minicardStyles['minicard__title']}`}>{title}</p>
      <img className={`${minicardStyles['minicard__image']}`} src={image} alt={title} />
      <p className={`${minicardStyles['minicard__text']}`}>{text}</p>
    </div>
  )
}

export default Minicard;