import React, { useState, useEffect } from 'react';
import CalcRow from '../calcRow/calcRow';
import sentImage from '../../images/sent.png'
import calcStyles from './calc.module.css';

const Calc = ({ data }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [checked, setChecked] = useState([]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [showCalc, setShowCalc] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  // dev API endpoint
  //const mailEndpoint = 'http://localhost:3333/api/mail';

  // prod API endpoint
  const mailEndpoint = 'https://web-jet.ru/api/mail';

  const handleRowClick = (row) => {
    setSelectedId(selectedId === row.id ? null : row.id);
  };

  const handleRowCheck = (row) => {
    const index = checked.indexOf(row.id);
    const array = [...checked];
    if (index > -1) {
      array.splice(index, 1);
      setChecked(array);
    }
    else {
      setChecked([...checked, row.id]);
    }
  };

  let sum = 0;
  let days = 0;
  checked.forEach(id => {
    sum += data.find(row => row.id === id).price;
    days += data.find(row => row.id === id).interval;
  });

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleForm = (e) => {
    setLoading(true);
  }

  useEffect(() => {
    const calcSent = localStorage.getItem('calcSent');

    if (!calcSent || (+calcSent + 60000 < +new Date())) {
      setShowCalc(true);
    }
  }, []);

  useEffect(() => {
    if (loading) {

      if (email) {
        if (!isValidEmail(email)) {
          setError('Введён некорректный e-mail!');
          setLoading(false);
          return;
        }
      }

      setError(false);

      const text = `
Имя: ${name}<br/>
${email ? 'E-mail: ' + email + '<br/>' : ''}
${phone ? 'Телефон: ' + phone + '<br/>' : ''}
<br/>

${checked?.map((id, index) => {
        const item = data.find(row => row.id === id);
        if (item) {
          return (index + 1) + ') ' + item.name;
        }
        else {
          return '';
        }
      }).join('<br/>')}

${(checked?.length ?? 0) > 0 ? '<br/><br/>Итого: от ' + days + ' дней, от ' + sum + ' руб.<br/>' : ''}
    `;

      const axios = require('axios').default;
      axios.post(mailEndpoint, {
        html: text.replace('\n', '<br/>'),
      })
        .then(response => {
          setSent(true);
          localStorage.setItem('calcSent', +new Date());
          return response;
        })
        .catch(error => {
          setError('Не удалось отправить запрос, попробуйте повторить позже.');
          return error;
        });

      setLoading(false);
    }
  }, [loading]);

  const formDisabled = !name || (!email && !phone) || sent;

  return (
    <div>
      <div className={calcStyles.calc}>
        {data.map(row => <CalcRow
          key={row.id}
          data={row}
          onClick={(e) => handleRowClick(row)}
          expanded={selectedId === row.id}
          onCheck={(e) => handleRowCheck(row)}
          checked={checked.includes(row.id)} />
        )}
        {(sum > 0 || days > 0) && <CalcRow isTotal={true} interval={days} price={sum} />}
      </div>
      {showCalc && <div className={calcStyles.calcFeedback}>
        {!sent && <span className={calcStyles.calcCaption}>Укажите свои данные и получите бесплатную консультацию.</span>}
        {error && <span className={calcStyles.calcError}>{error}</span>}
        {!sent && <div className={calcStyles.calcForm}>
          <input className={calcStyles.calcInput} disabled={loading} placeholder="Имя" type="text" value={name} onInput={e => setName(e.target.value)} />
          <input className={calcStyles.calcInput} disabled={loading} placeholder="E-mail" type="text" value={email} onInput={e => setEmail(e.target.value)} />
          <input className={calcStyles.calcInput} disabled={loading} placeholder="Телефон" type="text" value={phone} onInput={e => setPhone(e.target.value.replace(/[^0-9+]+/g, ''))} />
          <button disabled={loading} className={`${(loading || formDisabled) && calcStyles['calcButtonDisabled']} ${calcStyles['calcButton']}`} onClick={handleForm}>
            Отправить
          </button>
        </div>}
        {sent && <img src={sentImage} alt="Запрос отправлен" className={calcStyles.calcSent} />}
        {sent && <span style={{ textAlign: 'center' }} className={calcStyles.calcCaption}>Спасибо, мы скоро свяжемся с вами!</span>}
      </div>}
    </div>
  )
}

export default Calc;