import React from 'react';
import { NavLink } from 'react-router-dom';

import footerStyles from './footer.module.css';

import Navigation from '../navigation/navigation';

const Footer = () => {
  return (
    <footer className={footerStyles.footer__container}>
      <div className={footerStyles.footer}>
        <NavLink to="/" className={`${footerStyles.footer__link}`}>
          &#169; {new Date().getFullYear()} webJet
        </NavLink>
        <span className={`${footerStyles.footer__info}`}>
          Иконки и иллюстрации от Icons8.<br />
          Изображения от Unsplash.
        </span>
      </div>
    </footer>
  );
}

export default Footer;