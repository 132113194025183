import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import pageStyles from './page.module.css';

const Page = ({ title, subtitle, image, backImage, children }) => {

  const bannerParallax = useParallax({ speed: 1 });
  const bannerCaptionParallax = useParallax({ speed: 1});
  const bannerImageParallax = useParallax({ speed: 1 });

  const contentParallax = useParallax({ speed: 1 });

  return (
    <main className={pageStyles.page}>
      <div ref={bannerParallax.ref} style={{backgroundImage: 'url('+backImage+')'}} className={pageStyles.page__banner}>
        <div ref={bannerCaptionParallax.ref} className={`${pageStyles['page__banner-content']}`}>
          <div className={`${pageStyles['page__banner-caption']}`}>
            <h2 className={`${pageStyles['page__banner-subtitle']}`}>{subtitle}</h2>
            <h2 className={`${pageStyles['page__banner-title']}`}>{title}</h2>
          </div>
          <div ref={bannerImageParallax.ref} style={{backgroundImage: 'url('+image+')'}} className={`${pageStyles['page__banner-image']}`}>
          </div>
        </div>
      </div>
      <div ref={contentParallax.ref} className={pageStyles.page__content}>
      {children}
      </div>
    </main>
  );
}

export default Page;