import React from 'react';

import aboutBackImage from '../../images/evo.jpg'

import Page from '../page/page';

import appStyles from '../app/app.module.css';

const About = () => {
  return (
    <Page title={'О компании'} backImage={aboutBackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['caption']}`}>
          <p className={`${appStyles['caption__text']}`}>&nbsp;</p>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']}`}>
        <p className={`${appStyles['content__text']}`}>
          WebJet — это высокоорганизованная команда сертифицированных программистов, аналитиков, тестировщиков, менеджеров и администраторов.
        </p>
        <br /><br />
        <p className={`${appStyles['content__text']}`}>
          Подключаемся к работе на любом этапе формирования бизнеса, упрощая, систематизируя и оптимизируя работу всей компании и каждого ее сотрудника.
        </p>
        <br /><br />
        <p className={`${appStyles['content__text']}`}>
          Среди кейсов WebJet масштабные проекты по недвижимости, торговле, производству, рекламе, сфере услуг, разработкам в формате B2B и B2C.
        </p>
        <br /><br />
        <p className={`${appStyles['content__text']}`}>
          Беремся за проекты любой сложности и предлагаем услуги:
          <ul>
            <li>Создание сайтов.</li>
            <li>Развитие функционирующих веб-платформ.</li>
            <li>Поддержка 24/7 на любой срок.</li>
            <li>Автоматизация, интеграция и оцифровка бизнес-процессов.</li>
            <li>Внедрение облачных и коробочных продуктов Bitrix24 на предприятиях любой направленности.</li>
            <li>Установка и глубокая кастомизация CRM-систем с встройкой в интерфейс, настройкой чат-ботов и веб-хуков.</li>
            <li>Интеграция с 1С, платежными системами, базами данных, личными кабинетами, IP-телефонией, сайтами, интернет-магазинами, шлюзами, REST API.</li>
            <li>Разработка дополнительного функционала: мобильных приложений, специальных отчетов, системы документооборота и др.</li>
            <li>Установка решений с сохранением обновляемости, поддерживаем работу через helpdesk-систему.</li>
            <li>Построение эффективного отдела продаж с тренингами и аудитом.</li>
            <li>Выездное обучения сотрудников, онлайн-консультирование.</li>
          </ul>
        </p>
        <br />
        <p className={`${appStyles['content__text']}`}>
          Придерживаемся принципов прозрачного ценообразования, контроля выполнения задач заказчиком, постоплатной системы расчетов.
        </p>
        <br /><br />
        <p className={`${appStyles['content__text']}`}>
          Не существует одинаковых клиентов, поэтому многолетний опыт и огромные наработки в сфере веб-разработок помогают нам создавать уникальные IT-продукты, которые не только повышают продажи, но и оптимизируют и упрощают работу сотрудников.
        </p>
      </div>
    </Page>
  );
}

export default About;