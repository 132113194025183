import React, { useState } from 'react';
import Lottie from "lottie-react";

import b24BoxImage from '../../images/box.png'
import b24BoxBackImage from '../../images/wv.jpg'
import fireIcon from '../../images/fireIcon.json'
import boxIcon from '../../images/boxIcon.json'
import cloudIcon from '../../images/cloudIcon.json'
import rocketIcon from '../../images/rocketIcon.json'
import usersIcon from '../../images/usersIcon.png'
import manyUsersIcon from '../../images/manyUsersIcon.png'

import Page from '../../components/page/page';
import PricingCard from '../../components/pricing-card/pricing-card';

import appStyles from '../../components/app/app.module.css';

const B24Box = ({ orderClick }) => {
  const [usersNumber, setUsersNumber] = useState(50);

  return (
    <Page subtitle={'Битрикс24'} title={'Коробочный'} backImage={b24BoxBackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['caption']}`}>
          <p className={`${appStyles['caption__text']}`}>Выберите редакцию</p>
        </div>
      </div>
      <div className={`${appStyles['table']} ${appStyles['table__triple']}`}>
        <PricingCard
          title={'Интернет-магазин + CRM'}
          about={'Для эффективной работы небольших компаний и отделов продаж'}
          orderClick={orderClick}
          usersNumber={12}
          usersNoun={'пользователей'}
          /*
          priceOld={'99 000'}
          priceCurrentNumber={'84 150'}
          priceDiscount={'-15%'}
          */
          priceCurrentNumber={'99 000'}
          priceCurrentText={' ₽\u00A0\u00A0\u00A0\u00A0\u00A0'}
          priceAbout={'лицензия 12 мес'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Экстранет </span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>eCommerce-платформа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>Документы Онлайн</span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>Многодепартаментность</span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>Веб-кластер</span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>VIP поддержка 24/7</span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Корпоративный портал'}
          about={'Для совместной работы всей компании или рабочих групп'}
          orderClick={orderClick}
          usersNumber={usersNumber}
          usersNumbers={[50, 100, 250, 500]}
          usersNumberClick={setUsersNumber}
          usersNoun={'пользователей'}
          /*
          priceOld={usersNumber === 500
            ? '499 000'
            : usersNumber === 250
              ? '299 000'
              : usersNumber === 100
                ? '199 000'
                : '139 000'}
          priceCurrentNumber={usersNumber === 500
            ? '424 150'
            : usersNumber === 250
              ? '254 150'
              : usersNumber === 100
                ? '169 150'
                : '118 150'}
          priceDiscount={'-15%'}
          */
          priceCurrentNumber={usersNumber === 500
            ? '499 000'
            : usersNumber === 250
              ? '299 000'
              : usersNumber === 100
                ? '199 000'
                : '139 000'}
          priceCurrentText={' ₽\u00A0\u00A0\u00A0\u00A0\u00A0'}
          priceAbout={'лицензия 12 мес'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Экстранет </span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>eCommerce-платформа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Документы Онлайн</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>Многодепартаментность</span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>Веб-кластер</span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']} ${appStyles['table__card-disabled']}`}>VIP поддержка 24/7</span>
            </li>
          </ul>
        </PricingCard>
        <PricingCard
          title={'Энтерпрайз'}
          about={'Для крупного и среднего бизнеса'}
          orderClick={orderClick}
          usersNumber={'1000+'}
          usersNoun={'пользователей'}
          //priceOld={'\u00A0'}
          priceCurrentNumber={'999 000'}
          priceCurrentText={' ₽\u00A0\u00A0\u00A0\u00A0\u00A0'}
          priceAbout={'лицензия 12 мес'}
        >
          <ul className={`${appStyles['table__card-list']}`}>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Экстранет </span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>eCommerce-платформа</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Документы Онлайн</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Многодепартаментность</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>Веб-кластер</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
            <li>
              <span className={`${appStyles['table__card-listItem']}`}>VIP поддержка 24/7</span>
              <span className={`${appStyles['table__card-listItemLevel']} ${appStyles['table__card-listItemLevelExists']}`}></span>
            </li>
          </ul>
        </PricingCard>
      </div>
    </Page>
  );
}

export default B24Box;