class Auth {
  constructor({ url }) {
    this._apiUrl = url;
  }

  login({ email, password }) {
    return fetch(`${this._apiUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
      })
    })
      .then(this._checkResponse);
  }

  register({ email, password }) {
    return fetch(`${this._apiUrl}/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
      })
    })
      .then(this._checkResponse);
  }

  check({ token }) {
    return fetch(`${this._apiUrl}/check`, {
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`
      }
    })
      .then(this._checkResponse);
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res.status);
  }
}

const auth = new Auth({
  // url: 'http://localhost:3333/api'
  // url: 'http://192.168.1.137:3333/api'
  // url: 'https://web-jet.ru/api'
  url: 'https://devbuild.ru/api'
});

export default auth;