import React, { useState, useEffect, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js'
import { NavLink } from 'react-router-dom';

import { EDITOR_TOOLS } from '../../utils/editor-tools'

import adminBackImage from '../../images/zyuda.jpg'

import Page from '../../components/page/page';
import appStyles from '../../components/app/app.module.css'

import api from '../../utils/api';
import auth from '../../utils/auth';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';

import AuthForm from '../../components/auth-form/auth-form';
import Button from '../../ui/button/button';
import buttonDesign from '../../constants/button-design';


const Admin = () => {
  const ReactEditorJS = createReactEditorJS()

  const NEWS = 'news';
  const ARTICLE = 'article';
  const PROMO = 'promo';

  const [currentUser, setCurrentUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState();

  const [images, setImages] = useState([]);

  const [type, setType] = useState(NEWS);
  const [title, setTitle] = useState(null);
  const [desc, setDesc] = useState(null);
  const [link, setLink] = useState(null);
  const [published, setPublished] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [body, setBody] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [saved, setSaved] = useState(false);

  const hiddenImageFileInput = useRef(null);

  const handleOnChange = async (api) => {
    const body = await api.saver.save();
    setBody(body);
  };

  const blocks = {
    "time": 1658955144324,
    "blocks": [
      {
        "id": "JjKqGkfXUC",
        "type": "header",
        "data": {
          "text": "Editor.js",
          "level": 2
        }
      },
      {
        "id": "rKsN55y1c7",
        "type": "paragraph",
        "data": {
          "text": "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text."
        }
      },
      {
        "id": "yOVHmOHyXQ",
        "type": "header",
        "data": {
          "text": "Key features",
          "level": 3
        }
      },
      {
        "id": "gHwtC3IWUM",
        "type": "list",
        "data": {
          "style": "unordered",
          "items": [
            "It is a block-styled editor",
            "It returns clean data output in JSON",
            "Designed to be extendable and pluggable with a simple API"
          ]
        }
      },
      {
        "id": "FpWTzJkGKp",
        "type": "header",
        "data": {
          "text": "What does it mean «block-styled editor»",
          "level": 3
        }
      },
      {
        "id": "tqzOBlmCVe",
        "type": "paragraph",
        "data": {
          "text": "Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class=\"cdx-marker\">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor's Core."
        }
      },
      {
        "id": "0Lftmlqg6s",
        "type": "paragraph",
        "data": {
          "text": "There are dozens of <a href=\"https://github.com/editor-js\">ready-to-use Blocks</a> and the <a href=\"https://editorjs.io/creating-a-block-tool\">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games."
        }
      },
      {
        "id": "Hb1hQhMotX",
        "type": "header",
        "data": {
          "text": "What does it mean clean data output",
          "level": 3
        }
      },
      {
        "id": "GB1Eq5ONZh",
        "type": "paragraph",
        "data": {
          "text": "Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below"
        }
      },
      {
        "id": "j-9L8_N6uC",
        "type": "paragraph",
        "data": {
          "text": "Given data can be used as you want: render with HTML for <code class=\"inline-code\">Web clients</code>, render natively for <code class=\"inline-code\">mobile apps</code>, create markup for <code class=\"inline-code\">Facebook Instant Articles</code> or <code class=\"inline-code\">Google AMP</code>, generate an <code class=\"inline-code\">audio version</code> and so on."
        }
      },
      {
        "id": "w-oeJgjxrH",
        "type": "paragraph",
        "data": {
          "text": "Clean data is useful to sanitize, validate and process on the backend."
        }
      },
      {
        "id": "3Jjbvm1voP",
        "type": "delimiter",
        "data": {}
      },
      {
        "id": "pwOOIb8zB2",
        "type": "paragraph",
        "data": {
          "text": "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏"
        }
      },
      {
        "id": "dd8iT-SE8-",
        "type": "image",
        "data": {
          "file": {
            "url": "https://codex.so/public/app/img/external/codex2x.png"
          },
          "caption": "",
          "withBorder": false,
          "stretched": false,
          "withBackground": false
        }
      }
    ],
    "version": "2.24.3"
  };

  useEffect(() => {
    check({ token: localStorage.getItem('token') });
  }, [loggedIn]);

  useEffect(() => {
    setType(record?.type ?? NEWS);
    setTitle(record?.title ?? '');
    setDesc(record?.desc ?? '');
    setLink(record?.link ?? '');
    setPublished(record?.published ?? false);
    setImageUrl(record?.image ?? '');
    setBody(record?.body);
  }, [record]);

  useEffect(() => {
    setSaved(false);
  }, [type, title, desc, link, imageUrl, published, body]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    getRecords();
    getImages();
    api.setImageAddCallback(getImages);
  }, [currentUser]);

  const getImages = () => {
    api.getImages()
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getRecords = () => {
    api.getAllRecords()
      .then((res) => {
        setRecords(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  const logout = () => {
    setLoggedIn(false);
    setEmail('');
    setCurrentUser(null);
    localStorage.removeItem('token');
  }

  const login = ({ email, password }) => {
    auth.login({ email, password })
      .then((res) => {
        localStorage.setItem('token', res.token);
        setLoggedIn(true);
      })
      .catch((statusCode) => {
        alert(`${statusCode === 400 ? 'Не передано одно из полей!' : statusCode === 401 ? 'Пользователь с email не найден!' : 'Что-то пошло не так!'} Попробуйте ещё раз.`);
      });
  }

  const check = ({ token }) => {
    if (!token) {
      return;
    }

    auth.check({ token })
      .then((res) => {
        setLoggedIn(true);
        setCurrentUser(res.data);
      })
      .catch((statusCode) => {
        logout();
      });
  }

  const makeId = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const createRecord = () => {
    api.createRecord({
      title: "Новая запись",
      type: NEWS,
      desc: "Описание записи",
      image: "",
      link: makeId(8),
      body: { time: 1658957617886, blocks: [{ id: "7Zhd3ubdPk", type: "paragraph", data: { "text": "Привет, мир!" } }], version: "2.25.0" },
    })
      .then((res) => {
        getRecords();
        setRecord(res.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  const updateRecord = () => {
    api.updateRecord(record._id, {
      type: type,
      title: title,
      desc: desc,
      link: link,
      published: published,
      image: imageUrl,
      body: body,
    })
      .then((res) => {
        setSaved(true);
        getRecords();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  const deleteRecord = () => {
    api.deleteRecord(record._id)
      .then((res) => {
        setRecord(null);
        getRecords();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  const addImage = () => {
    hiddenImageFileInput.current.click();
  }

  const handleImageFileInput = event => {
    const fileUploaded = event.target.files[0];

    api.addImage(fileUploaded)
      .then((res) => {
        getImages();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteImage = (id) => {
    api.deleteImage(id)
      .then((res) => {
        getImages();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    login({ email, password });
  }

  Date.prototype.ddmmyyyy = function () {
    var mm = this.getMonth() + 1;
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd + '.',
      (mm > 9 ? '' : '0') + mm + '.',
      this.getFullYear()
    ].join('');
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onDragEnter = (e) => {
    e.stopPropagation();
  }

  const onFileDrop = (e) => {
    e.stopPropagation();

    const html = e.dataTransfer.getData('text/html');
    const src = new DOMParser().parseFromString(html, "text/html").querySelector('img').src;

    if (src) {
      setImageUrl(src.replace(api.getImageRootUrl(), ''));
    }
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <div className={`${appStyles['admin_page']}`}>
        <div className={`${appStyles['admin_page_header']}`}>
          <NavLink to="/" className={`${appStyles['admin_page_logo']}`} >webJet</NavLink>
          {currentUser && <div className={`${appStyles['admin_page_row']}`}>
            <h2 className={`${appStyles['admin_page_text']} ${appStyles['admin_page_login']}`}>{currentUser.firstName}</h2>
            <Button text="Logout" design={buttonDesign.BLUE_OUTLINE} onClick={(e) => logout()} />
          </div>}
        </div>
        {!currentUser && <div className={`${appStyles['admin_page_form']}`}>
          <AuthForm title="Вход" name="login" buttonText={"Войти"} onSubmit={handleSubmit} children={
            <>
              <label>
                <input name="name" type="text" id="email-input" minLength="2" maxLength="40" placeholder="Email" value={email || ''} onChange={(e) => setEmail(e.target.value)} required />
                <span className="name-input-error auth-form__input-error auth-form__input-error_type_name"></span>
              </label>
              <label>
                <input name="about" type="password" id="password-input" minLength="2" maxLength="200" placeholder="Пароль" value={password || ''} onChange={(e) => setPassword(e.target.value)} required />
                <span className="about-input-error auth-form__input-error auth-form__input-error_type_about"></span>
              </label>
            </>
          } />
        </div>
        }
        {currentUser && <div className={`${appStyles['admin_page_workspace']}`}>
          <div className={`${appStyles['admin_page_list']}`}>
            <Button text="Новая запись" onClick={(e) => createRecord()} />
            {records?.map((item) => <div className={`${appStyles['admin_page_item']} ${record?._id === item._id && appStyles['admin_page_item__selected']}`} key={item._id} onClick={(e) => setRecord(item)}>
              <span className={`${appStyles['admin_page_item_date']}`}>{new Date(item.updatedAt).ddmmyyyy()}</span>
              <span className={`${appStyles['admin_page_item_type']} ${item.type === NEWS ? appStyles['admin_page_item_type__news'] : item.type === ARTICLE ? appStyles['admin_page_item_type__article'] : item.type === PROMO ? appStyles['admin_page_item_type__promo'] : ''}`}>{item.type === NEWS ? 'Новость' : item.type === ARTICLE ? 'Статья' : item.type === PROMO ? 'Акция' : ''}</span>
              <span className={`${appStyles['admin_page_item_title']}`}>{(item.published ? '' : '[OFF] ') + item.title}</span>
            </div>)}
          </div>
          <div className={`${appStyles['admin_page_list']}`}>
            <Button text="Добавить" onClick={(e) => addImage()} />
            <input
              type="file"
              ref={hiddenImageFileInput}
              onChange={handleImageFileInput}
              style={{ display: 'none' }}
            />
            {images?.map((item) => <div className={`${appStyles['admin_page_item']}`} key={item._id}>
              <img src={api.getImageUrl(item.filename)} alt={item._id} />
              <Button text="Удалить" onClick={(e) => deleteImage(item._id)} />
            </div>)}
          </div>
          <div className={`${appStyles['admin_page_editor']}`}>
            <div style={{ display: record ? 'block' : 'none' }}><ReactEditorJS value={record?.body} tools={EDITOR_TOOLS} onChange={handleOnChange} /></div>
          </div>
          {record && <div className={`${appStyles['admin_page_list']}`}>
            <span>Тип</span>
            <Button design={type === NEWS ? buttonDesign.BLUE : buttonDesign.BLUE_OUTLINE} text="Новость" onClick={(e) => setType(NEWS)} />
            <Button design={type === ARTICLE ? buttonDesign.BLUE : buttonDesign.BLUE_OUTLINE} text="Статья" onClick={(e) => setType(ARTICLE)} />
            <Button design={type === PROMO ? buttonDesign.BLUE : buttonDesign.BLUE_OUTLINE} text="Акция" onClick={(e) => setType(PROMO)} />
            <span>Заголовок</span>
            <input className={`${appStyles['admin_page_input']}`} name="title" type="text" id="title-input" minLength="2" maxLength="256" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
            <span>Описание</span>
            <input className={`${appStyles['admin_page_input']}`} name="desc" type="text" id="desc-input" placeholder="Description" value={desc} onChange={(e) => setDesc(e.target.value)} required />
            <span>Ссылка</span>
            <input className={`${appStyles['admin_page_input']}`} name="link" type="text" id="desc-link" minLength="2" maxLength="256" placeholder="Link" value={link} onChange={(e) => setLink(e.target.value)} required />
            {false && <input className={`${appStyles['admin_page_input']}`} name="image" type="text" id="desc-image" minLength="2" maxLength="1024" placeholder="Image" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} required />}
            <span>Изображение</span>
            <div className={`${appStyles['admin_page_input']}`}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onDrop={onFileDrop}>
              {imageUrl && <>
                <img className={`${appStyles['admin_page_image']}`} src={api.getImageRootUrl() + imageUrl} alt='' />
              </>}
              <div className={`${appStyles['admin_page_line']}`}>
                {imageUrl && <Button text="Очистить" onClick={(e) => setImageUrl('')} />}
                {imageUrl != record?.image && <Button text="Откатить" onClick={(e) => setImageUrl(record?.image)} />}
              </div>
              {!imageUrl && 'Переместите изображение сюда'}
            </div>
            <span>Опубликовано</span>
            <div className={`${appStyles['admin_page_line']}`}>
              <Button design={published ? buttonDesign.BLUE : buttonDesign.BLUE_OUTLINE} text="Да" onClick={(e) => setPublished(true)} />
              <Button design={!published ? buttonDesign.BLUE : buttonDesign.BLUE_OUTLINE} text="Нет" onClick={(e) => setPublished(false)} />
            </div>
            <Button disabled={saved} text={saved ? "Сохранено" : "Сохранить"} design={buttonDesign.GREEN} onClick={(e) => updateRecord()} />
            <Button text="Удалить" onClick={(e) => deleteRecord()} />
          </div>}
        </div>}
      </div>
    </CurrentUserContext.Provider>
  );
}

export default Admin;