import React from 'react';
import { NavLink } from 'react-router-dom';

import bottomNavigationStyles from './bottomNavigation.module.css';

const BottomNavigation = ({ show }) => {
  return (
    <nav className={`${bottomNavigationStyles.bottomNavigation} ${show && bottomNavigationStyles.bottomNavigationVisible}`}>
      <ul className={bottomNavigationStyles.navigation__links}>
        <div className={bottomNavigationStyles.navigation__container}>
          <li className={bottomNavigationStyles.navigation__link}>
            <NavLink className={bottomNavigationStyles.navigation__navlink} to="/">Главная
              {/*<svg className={bottomNavigationStyles.navigation__icon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"*/}
              {/*  viewBox="0 0 48 48"><path d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9	C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52	C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"></path></svg>*/}
            </NavLink>
          </li>
          <li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/b24">Битрикс24</NavLink></li>
        </div>
        <div className={bottomNavigationStyles.navigation__container}>
          <li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/cms">Управление сайтом</NavLink></li>
          <li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/about">О компании</NavLink></li>
          <li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/blog">Блог</NavLink></li>
          {/*<li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/">Услуги</NavLink></li>*/}
          {/*<li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/cases">Портфолио</NavLink></li>*/}
          {/*<li className={bottomNavigationStyles.navigation__link}><NavLink className={bottomNavigationStyles.navigation__navlink} to="/about">О компании</NavLink></li>*/}
        </div>
      </ul>
    </nav>
  )
}

export default BottomNavigation;