import React from 'react';
import HeaderLink from '../headerLink/headerLink';

import navigationStyles from './navigation.module.css';

const Navigation = ({ orderClick }) => {
  return (
    <nav className={navigationStyles.navigation}>
      <ul className={navigationStyles.navigation__links}>
        <li className={navigationStyles.navigation__link}>
          <HeaderLink
            primary={{ link: '/b24', text: 'Битрикс24' }}
            secondary={[{ link: '/b24/cloud', text: 'Облачный' }, { link: '/b24/box', text: 'Коробочный' },]}
          />
        </li>
        <li className={navigationStyles.navigation__link}>
          <HeaderLink
            primary={{ link: '/cms', text: 'Управление сайтом' }}
          />
        </li>
        {/*<li className={navigationStyles.navigation__link}>*/}
        {/*  <HeaderLink*/}
        {/*    primary={{ link: '/', text: 'Услуги' }}*/}
        {/*  />*/}
        {/*</li>*/}
        {/*<li className={navigationStyles.navigation__link}>*/}
        {/*  <HeaderLink*/}
        {/*    primary={{ link: '/cases', text: 'Портфолио' }}*/}
        {/*  />*/}
        {/*</li>*/}
        <li className={navigationStyles.navigation__link}>
          <HeaderLink
            primary={{ link: '/about', text: 'О компании' }}
            secondary={[{ link: '/contacts', text: 'Контакты' }]}
          />
        </li>
        <li className={navigationStyles.navigation__link}>
          <HeaderLink
            primary={{ link: '/blog', text: 'Блог' }}
          />
        </li>
        <li className={navigationStyles.navigation__link}>
          <HeaderLink
            highlighted={true}
            primary={{ click: orderClick, text: 'Заявка' }}
          />
        </li>
      </ul>
    </nav>
  )
}

export default Navigation;