class Api {
  constructor({ url }) {
    this._apiUrl = url;
    this._imageAddCallback = null;
  }

  getImageRootUrl() {
    return `${this._apiUrl}/images`;
  }

  getImageUrl(filename) {
    return `${this._apiUrl}/images/${filename}`;
  }

  getImages() {
    let path = `${this._apiUrl}/images`;

    return fetch(path, {
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  setImageAddCallback(callback) {
    this._imageAddCallback = callback;
  }

  addImage(image) {
    var data = new FormData();
    data.append('image', image);

    return fetch(`${this._apiUrl}/images`, {
      method: 'POST',
      headers: {
        authorization: this._getToken(),
      },
      body: data,
    })
      .then((res) => this._checkResponse(res, this._imageAddCallback));
  }

  deleteImage(id) {

    console.log(id)

    return fetch(`${this._apiUrl}/images/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  getRecords(type, link) {
    let path = `${this._apiUrl}/records`;

    if (link) {
      path += `?link=${link}`;
    }
    else if (type) {
      path += `?type=${type}`;
    }

    return fetch(path, {
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  getAllRecords(type, link) {
    let path = `${this._apiUrl}/allRecords`;

    if (link) {
      path += `?link=${link}`;
    }
    else if (type) {
      path += `?type=${type}`;
    }

    return fetch(path, {
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  getRecord(id) {
    return fetch(`${this._apiUrl}/records/{id}`, {
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  createRecord(record) {
    return fetch(`${this._apiUrl}/records`, {
      method: 'POST',
      headers: {
        authorization: this._getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(record)
    })
      .then(this._checkResponse);
  }

  updateRecord(id, record) {

    console.log(id);
    console.log(JSON.stringify(record));

    return fetch(`${this._apiUrl}/records/${id}`, {
      method: 'PATCH',
      headers: {
        authorization: this._getToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(record)
    })
      .then(this._checkResponse);
  }

  deleteRecord(id) {
    return fetch(`${this._apiUrl}/records/${id}`, {
      method: 'DELETE',
      headers: {
        authorization: this._getToken(),
      }
    })
      .then(this._checkResponse);
  }

  _checkResponse(res, callback) {
    if (res.ok) {

      if (callback) {
        callback();
      }

      return res.json();
    }
    return Promise.reject(`Ошибка: ${res.status}`);
  }

  _getToken() {
    return 'Bearer ' + localStorage.getItem('token');
  }
}

const api = new Api({
  // url: 'http://192.168.1.137:3333/api'
  // url: 'https://web-jet.ru/api'
  // url: 'http://localhost:3333/api'
  url: 'https://devbuild.ru/api'
});

export default api;