import React from 'react';

import contactsBackImage from '../../images/contacts-bg.jpg'

import Page from '../page/page';

import appStyles from '../app/app.module.css';
import mainStyles from '../../pages/main/main.module.css';

const Contacts = () => {
  return (
    <Page title={'Контакты'} backImage={contactsBackImage}>
      <div className={`${appStyles['content__center']}`}>
        <div className={`${appStyles['caption']}`}>
          <p className={`${appStyles['caption__text']}`}>&nbsp;</p>
        </div>
      </div>
      <div className={`${appStyles['content__narrow']}`}>
        <div>
              <div className={`${mainStyles['main__content']} ${appStyles['contacts_content']}`}>
                <iframe style={{ minHeight: '400px', }} className={`${mainStyles['main__content']} ${mainStyles['main__feedback']} ${mainStyles['main__map']}`} src="https://yandex.ru/map-widget/v1/-/CCU5iKTEWA" allowFullScreen={true}></iframe>
                <p className={`${appStyles['content__text']} ${appStyles['content__contacts']}`}>
                  <span className={`${appStyles['content__annotation']}`}>Адрес</span><br />г. Москва,<br />Проектируемый проезд № 4062, д. 6, стр.16, бизнес-центр PortPlaza<br />
                  <span className={`${appStyles['content__annotation']}`}>Время работы</span><br />Пн-Пт: 10.00 — 20.00<br />
                  <span className={`${appStyles['content__annotation']}`}>Телефон</span><br /><a className={`${appStyles['content__simpleLink']}`} href="tel:+74993900455" nofollow="true">+7 499 390-04-55</a><br />
                  <span className={`${appStyles['content__annotation']}`}>Email</span><br /><a className={`${appStyles['content__simpleLink']}`} href="mailto:welcome@web-jet.ru" nofollow="true">support@web-jet.ru</a>
                </p>
              </div>
            </div>
      </div>
    </Page>
  );
}

export default Contacts;