import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import appStyles from './app.module.css';

import Header from '../header/header';
import Main from '../../pages/main/main';
import Footer from '../footer/footer';
import NotFound from '../notFound/notFound';
import BottomNavigation from '../bottomNavigation/bottomNavigation';
import B24 from '../../pages/b24/b24';
import B24Cloud from '../../pages/b24-cloud/b24-cloud';
import B24Box from '../../pages/b24-box/b24-box';
import Cms from '../../pages/cms/cms';
import Cases from '../cases/cases';
import About from '../about/about';
import Contacts from '../contacts/contacts';
import FormWrapper from '../formWrapper/formWrapper';
import Blog from '../../pages/blog/blog';
import { b24form } from '../../utils/b24';

const App = () => {
  const { pathname } = useLocation();
  const [showBottomNav, setShowBottomNav] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [pathname, showOrderForm]);

  useEffect(() => {
    window.onscroll = () => {
      if ((window.pageYOffset > window.outerHeight / 4)) {
        setShowBottomNav(true);
      }

      if ((window.pageYOffset <= window.outerHeight / 4)) {
        setShowBottomNav(false);
      }
    }
  }, []);

  const openOrder = () => {
    setShowOrderForm(true);
  }

  return (
    <ParallaxProvider>
      <div className={`${appStyles.app}`}>
        <Header orderClick={openOrder} />
        {<div className={`${appStyles.orderForm__container} ${showOrderForm ? appStyles.orderFormVisible : appStyles.orderFormHidden}`}>
          <div className={`${appStyles.orderForm}`}>
            <p className={`${appStyles['content__text']} ${appStyles['content__caption']}`}>
              <span>Заявка</span>
              <div className={`${appStyles.orderForm__close}`} onClick={() => setShowOrderForm(false)}></div>
            </p>
            <p className={`${appStyles['content__text']} ${appStyles['content__double']}`}>
              Отправьте запрос на автоматизацию или задайте любой интересующий вас вопрос в поле <span className={`${appStyles['content__highlight']}`}>комментарий</span>.
            </p>
            <script data-b24-form="inline/7/ovkwrm" data-skip-moving="true">
              {b24form(window, document, 'https://cdn-ru.bitrix24.ru/b20630534/crm/form/loader_7.js')}
            </script>
          </div>
        </div>}
        <Routes>
          <Route exact path="/b24" element={<B24 orderClick={openOrder}/>} />
          <Route exact path="/b24/cloud" element={<B24Cloud orderClick={openOrder}/>} />
          <Route exact path="/b24/box" element={<B24Box orderClick={openOrder}/>} />
          <Route exact path="/cms" element={<Cms orderClick={openOrder}/>} />
          <Route exact path="/cases" element={<Cases />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/contacts" element={<Contacts />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route path="/blog/:link" element={<Blog />} />
          <Route exact path="/" element={<Main />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
        {<BottomNavigation show={showBottomNav} />}
      </div>
    </ParallaxProvider>
  );
}

export default App;
