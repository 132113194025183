import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import headerLinkStyles from './headerLink.module.css';

const HeaderLink = ({ highlighted, primary, secondary }) => {
  const [cardVisible, setCardVisible] = useState(false);
  const [cardMargin, setCardMargin] = useState(0);

  const showCard = (e, fromCard) => {
    if (!secondary) {
      return;
    }

    setCardVisible(true);

    if (fromCard) {
      return;
    }

    const primaryWidth = e.target.offsetWidth;
    const cardWidth = e.target.nextSibling.offsetWidth;

    if (primaryWidth >= cardWidth) {
      setCardMargin((primaryWidth - cardWidth) / 2);
    }
    else {
      setCardMargin(-(cardWidth - primaryWidth) / 2);
    }
  }

  const hideCard = (e) => {
    if (!secondary) {
      return;
    }

    setCardVisible(false);
  }

  return (
    <div className={headerLinkStyles.headerLink} onMouseOver={(e) => showCard(e, true)} onMouseLeave={hideCard}>
      {primary?.link && <NavLink className={`${headerLinkStyles.headerLink__navlink} ${cardVisible && headerLinkStyles['headerLink__navlink-hovered']} ${highlighted && headerLinkStyles['headerLink__navlink-highlighted']}`} onMouseOver={(e) => showCard(e, false)} to={primary.link}>{primary.text}</NavLink>}
      {primary?.click && <div className={`${headerLinkStyles.headerLink__navlink} ${cardVisible && headerLinkStyles['headerLink__navlink-hovered']} ${highlighted && headerLinkStyles['headerLink__navlink-highlighted']}`} onMouseOver={(e) => showCard(e, false)} onClick={primary.click}>{primary.text}</div>}
      {secondary && <div style={{ marginLeft: `${cardMargin}px` }} className={`${headerLinkStyles.headerLink__card} ${cardVisible && headerLinkStyles['headerLink__card-visible']}`}>
        {secondary.map((item, index) => <NavLink key={index} className={`${headerLinkStyles.headerLink__navlink} ${headerLinkStyles['headerLink__card-navlink']}`} to={item.link}>{item.text}</NavLink>)}
      </div>}
    </div>
  )
}

export default HeaderLink;